  export function battle() {
    const wsuri = "wss://18csgo.com/ws/";
    // const wsuri = "wss://cz-chunxinhuanbao.com/ws/";
    // const wsuri = "ws://192.168.101.12:7654/";
    // const wsuri = "ws://192.168.149.1:7654/";
    return wsuri;
  }
  export function openBox() {
    const wsuri = "wss://18csgo.com/wss/";
    // const wsuri = "wss://cz-chunxinhuanbao.com/wss/";
    // const wsuri = "ws://192.168.101.12:7655/";
    // const wsuri = "ws://192.168.149.1:7655/";
    return wsuri;
  }